<template>
  <div
    class="tw-flex tw-flex-col tw-gap-1 tw-justify-center tw-items-center lg:tw-flex-row tw-text-center"
    :class="attributeClass"
  >
    <a
      v-if="amazonUrl"
      :href="amazonUrl"
      target="_blank"
      class="tw-text-base"
    >
      {{ asin }}
    </a>
    <div
      v-else
      class="tw-text-base"
    >
      {{ asin }}
    </div>
    <div class="tw-flex tw-flex-row tw-gap-2">
      <feather-icon
        v-b-tooltip.hover="'Copy To Clipboard'"
        class="text-primary tw-cursor-pointer ml-50"
        title="Copy To Clipboard"
        icon="CopyIcon"
        @click.stop="copyToClipboard(asin)"
      />
    </div>
  </div>
</template>
<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  name: 'AsinElement',
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    asin: {
      type: String,
      required: true,
    },
    amazonUrl: {
      type: String,
      required: false,
      default: null,
    },
    attributeClass: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
  },
  methods: {
    async copyToClipboard(value) {
      await navigator.clipboard.writeText(value)
      this.$bvToast.toast(value, {
        title: 'Copy To Clipboard',
        variant: 'info',
        solid: true,
      })
    },
  },
}
</script>

<style scoped lang="scss">

</style>
