<template>
  <div class="toolbar-filter-panel">
    <DxTextBox
      v-model="search"
      placeholder="Enter ASIN or keyword"
      :on-key-up="onFilterChange"
      value-change-event="keyup"
    />
    <DxTagBox
      v-model="marketplaces"
      :data-source="marketplaceOptions"
      :search-enabled="true"
      :max-displayed-tags="2"
      :show-multi-tag-only="false"
      display-expr="title"
      value-expr="id"
      placeholder="All Marketplaces"
      item-template="item"
      :on-value-changed="onFilterChange"
    >
      <template #item="{ data }">
        <div class="d-flex flex-row justify-content-between align-items-center">
          <div class="flex-1">
            {{ data.title }}
          </div>
          <div>
            <CountryFlag :country="data.country.toLowerCase()" />
          </div>
        </div>
      </template>
    </DxTagBox>
  </div>
</template>

<script>
import DxTextBox from 'devextreme-vue/text-box'
import DxTagBox from 'devextreme-vue/tag-box'
import CountryFlag from 'vue-country-flag'

export default {
  name: 'Filters',
  components: {
    DxTextBox,
    DxTagBox,
    CountryFlag,
  },
  data() {
    return {
      search: null,
      marketplaces: [],
      debounce: null,
    }
  },
  computed: {
    marketplaceOptions() {
      return this.$store.getters['app/availableMarketplaceOptions']
    },
  },
  methods: {
    onFilterChange() {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      this.debounce = setTimeout(() => {
        this.$emit('on-update', JSON.parse(JSON.stringify({
          search: this.search,
          marketplaces: this.marketplaces,
        })))
      }, 500)
    },
  },
}
</script>

<style scoped>

</style>
