<template>
  <DxDataGrid
    id="sellers-data-grid"
    ref="gridView"
    class="sxr-grid regular-text-color"
    :class="{ 'filter-panel-expanded': expandFilters }"
    width="100%"
    height="100%"
    :data-source="datasource"
    :show-borders="false"
    :show-row-lines="true"
    :show-column-lines="true"
    :remote-operations="true"
    :word-wrap-enabled="true"
    :no-data-text="noResultsFoundMessage"
    :column-auto-width="true"
    :allow-column-reordering="true"
    :allow-column-resizing="!isCurrentBreakPointXs"
    column-resizing-mode="widget"
    @exporting="onExporting"
    @exported="onExported"
  >
    <DxToolbar>
      <DxItem
        location="before"
        template="filterPanelTemplate"
      />
      <DxItem
        name="columnChooserButton"
      />
      <DxItem
        name="exportButton"
      />
    </DxToolbar>
    <DxHeaderFilter
      :visible="false"
      :hide-select-all-on-search="false"
    />
    <DxPaging :page-size="25" />
    <DxScrolling
      mode="virtual"
    />
    <DxStateStoring
      :enabled="false"
      type="localStorage"
      storage-key="sellers-data-grid-state"
    />
    <DxExport
      :enabled="true"
      :formats="['csv', 'xlsx']"
      :texts="{
        exportAll: 'Export to {0}'
      }"
    />
    <DxColumnChooser
      :enabled="true"
      mode="select"
    />
    <DxColumn
      data-field="image"
      caption="Image"
      data-type="string"
      :min-width="100"
      :width="100"
      :allow-resizing="false"
      :allow-sorting="false"
      :fixed="true"
      css-class="fixed-column-cell"
      fixed-position="left"
      cell-template="imageCellTemplate"
    />
    <DxColumn
      data-field="asin"
      caption="ASIN"
      data-type="string"
      :min-width="160"
      :width="160"
      :fixed="true"
      css-class="fixed-column-cell"
      fixed-position="left"
      cell-template="asinCellTemplate"
    />
    <DxColumn
      :allow-editing="false"
      :show-in-column-chooser="false"
      data-field="marketplace_id"
      caption="Marketplace"
      data-type="string"
      :visible="false"
      :min-width="100"
    />
    <DxColumn
      data-field="sellers"
      caption="Sellers"
      data-type="string"
      :min-width="140"
      cell-template="sellersCellTemplate"
    />
    <DxColumn
      :allow-editing="false"
      :show-in-column-chooser="false"
      data-field="new_last_24_hours_joined"
      caption="New Last 24 Hours Joined"
      data-type="string"
      :visible="false"
      :min-width="100"
    />
    <DxColumn
      :allow-editing="false"
      :show-in-column-chooser="false"
      data-field="new_last_24_hours_left"
      caption="New Last 24 Hours Left"
      data-type="string"
      :visible="false"
      :min-width="100"
    />
    <DxColumn
      :allow-editing="false"
      :show-in-column-chooser="false"
      data-field="new_last_30_days_joined"
      caption="New Last 30 Days Joined"
      data-type="string"
      :visible="false"
      :min-width="100"
    />
    <DxColumn
      :allow-editing="false"
      :show-in-column-chooser="false"
      data-field="new_last_30_days_left"
      caption="New Last 30 Days Left"
      data-type="string"
      :visible="false"
      :min-width="100"
    />
    <DxColumn
      data-field="new_last_24_hours"
      caption="New (24 Hours)"
      data-type="string"
      :min-width="140"
      cell-template="last24HoursCellTemplate"
    />
    <DxColumn
      data-field="new_last_30_days"
      caption="New (30 Days)"
      data-type="string"
      :min-width="140"
      cell-template="last30daysCellTemplate"
    />
    <DxColumn
      :show-in-column-chooser="false"
      :allow-editing="false"
      :allow-exporting="false"
      :allow-sorting="false"
      data-field="marketplace"
      caption=""
      data-type="string"
      :fixed="true"
      :allow-resizing="false"
      css-class="fixed-column-cell"
      fixed-position="left"
      :min-width="50"
      alignment="center"
      cell-template="marketplaceCell"
    />
    <template #filterPanelTemplate>
      <Filters
        :expand-filters="expandFilters"
        @on-show-filter-click="onShowFilterClick"
        @on-update="onFilterUpdate"
      />
    </template>
    <template #last30daysCellTemplate="{ data }">
      <div
        v-if="data.key.new_last_30_days.joined !== 0 || data.key.new_last_30_days.left !== 0"
        class="f-flex flex-column text-center"
      >
        <div class="d-flex flex-row justify-content-center align-items-center">
          <div
            class="d-flex flex-column"
            :class="{
              'text-danger' : data.key.new_last_30_days.total > 0,
              'text-muted' : data.key.new_last_30_days.total === 0,
            }"
          >
            <div>{{ data.key.new_last_30_days.total > 0 ? '+' : '' }}{{ data.key.new_last_30_days.total }}</div>
            <div class="small">
              Sellers
            </div>
          </div>
          <div class="d-flex flex-column">
            <b-progress
              value="100"
              max="100"
              class="progress-bar-danger"
              variant="danger"
              style="width: 60px"
            />
            <div class="small text-danger">
              {{ data.key.new_last_30_days.joined }} joined
            </div>
            <b-progress
              value="100"
              max="100"
              class="progress-bar-success"
              variant="success"
              style="width: 60px"
            />
            <div class="small text-success">
              {{ data.key.new_last_30_days.left * -1 }} left
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2 text-center"
      >
        <div>
          <feather-icon
            icon="UserIcon"
            size="14"
          />
        </div>
        <div class="small">
          No change
        </div>
      </div>
    </template>
    <template #last24HoursCellTemplate="{ data }">
      <div
        v-if="data.key.new_last_24_hours.left !== 0 || data.key.new_last_24_hours.joined !== 0"
        class="f-flex flex-column text-center"
      >
        <div class="d-flex flex-row justify-content-center align-items-center">
          <div
            class="d-flex flex-column"
            :class="{
              'text-danger' : data.key.new_last_24_hours.total > 0,
              'text-muted' : data.key.new_last_24_hours.total === 0,
            }"
          >
            <div>{{ data.key.new_last_24_hours.total > 0 ? '+' : '' }}{{ data.key.new_last_24_hours.total }}</div>
            <div class="small">
              Sellers
            </div>
          </div>
          <div class="d-flex flex-column">
            <b-progress
              value="100"
              max="100"
              class="progress-bar-danger"
              variant="danger"
              style="width: 60px"
            />
            <div class="small text-danger">
              {{ data.key.new_last_24_hours.joined }} joined
            </div>
            <b-progress
              value="100"
              max="100"
              class="progress-bar-success"
              variant="success"
              style="width: 60px"
            />
            <div class="small text-success">
              {{ data.key.new_last_24_hours.left * -1 }} left
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2 text-center"
      >
        <div>
          <feather-icon
            icon="UserIcon"
            size="14"
          />
        </div>
        <div class="small">
          No change
        </div>
      </div>
    </template>
    <template #sellersCellTemplate="{ data }">
      <div
        class="f-flex flex-column text-center"
        :class="{ 'text-danger': data.key.new_last_24_hours.total > 0 || data.key.new_last_30_days.total > 0}"
      >
        <div>{{ data.key.sellers }}</div>
        <div>
          <b-button
            size="xs"
            variant="outline-primary"
            @click.stop="onSellersViewClick(data.key)"
          >
            <feather-icon
              icon="ExternalLinkIcon"
              size="14"
            />
          </b-button>
        </div>
      </div>
    </template>
    <template #imageCellTemplate="{ data }">
      <div class="d-flex flex-column text-center">
        <ProductImage
          :size="!isCurrentBreakPointXs ? '59px' : '42px'"
          :src="data.key.image"
          :title="data.key.title"
          icon-size="32"
          :is-zoom-available="true"
          :marketplace="data.key.marketplace.marketplace_id"
          :asin="data.key.asin"
          :product-url="data.key.amazon_link"
          :attr-class="!isCurrentBreakPointXs ? 'tw-p-4' : 'tw-p-1.5'"
        />
      </div>
    </template>
    <template #marketplaceCell="{ data }">
      <CountryFlag
        :key="data.data.marketplace.country_code"
        :country="data.data.marketplace.country_code"
        :title="data.data.marketplace.country"
        :alt="data.data.marketplace.country"
        :size="isCurrentBreakPointXs ? 'small' : undefined"
      />
    </template>
    <template #asinCellTemplate="{ data }">
      <AsinElement
        :asin="data.data.asin"
        :amazon-url="data.data.amazon_url"
      />
    </template>
  </DxDataGrid>
</template>

<script>
import {
  BButton, BProgress, VBTooltip,
} from 'bootstrap-vue'
import {
  DxColumn,
  DxColumnChooser,
  DxDataGrid, DxExport,
  DxHeaderFilter,
  DxItem,
  DxPaging, DxScrolling, DxStateStoring,
  DxToolbar,
} from 'devextreme-vue/data-grid'
import CountryFlag from 'vue-country-flag'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver-es'
import SellersDataSource from '@/data/sellers.datasource'
import Filters from '@/views/sellers/components/Filters.vue'
import ProductImage from '@/components/ProductImage.vue'
import AsinElement from '@/components/AsinElement.vue'

export default {
  name: 'DataGrid',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    DxExport,
    AsinElement,
    ProductImage,
    Filters,
    DxDataGrid,
    DxColumn,
    DxColumnChooser,
    DxToolbar,
    DxItem,
    DxPaging,
    DxHeaderFilter,
    CountryFlag,
    DxScrolling,
    DxStateStoring,
    BProgress,
    BButton,
  },
  data() {
    return {
      noResultsFoundMessage: 'No Result Found',
      datasource: SellersDataSource.getInstance(this.$store),
      expandFilters: false,
    }
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
    gridInstance() {
      return this.$refs.gridView.instance
    },
  },
  methods: {
    isEmpty(value) {
      return value === undefined || value === null || value.length === 0
    },
    onFilterUpdate({ search, marketplaces }) {
      const filters = []
      if (search && search.length) {
        if (filters.length) {
          filters.push('and')
        }
        filters.push(['search', '=', search])
      }
      if (marketplaces && marketplaces.length) {
        if (filters.length) {
          filters.push('and')
        }
        filters.push(['marketplaces', '=', marketplaces])
      }

      if (filters.length) {
        this.gridInstance.filter(filters)
      } else {
        this.gridInstance.clearFilter()
      }
    },
    onSellersViewClick(data) {
      window.open(`${data.marketplace.url}/dp/${data.asin}/?aod=1`, '_newtab')
    },
    onShowFilterClick(value) {
      this.expandFilters = value
    },
    enableExportColumns() {
      this.gridInstance.columnOption('marketplace_id', 'visible', true)
      this.gridInstance.columnOption('new_last_24_hours_joined', 'visible', true)
      this.gridInstance.columnOption('new_last_24_hours_left', 'visible', true)
      this.gridInstance.columnOption('new_last_30_days_joined', 'visible', true)
      this.gridInstance.columnOption('new_last_30_days_left', 'visible', true)

      this.gridInstance.columnOption('image', 'visible', false)
      this.gridInstance.columnOption('new_last_24_hours', 'visible', false)
      this.gridInstance.columnOption('new_last_30_days', 'visible', false)
    },
    disableExportColumns() {
      this.gridInstance.columnOption('marketplace_id', 'visible', false)
      this.gridInstance.columnOption('new_last_24_hours_joined', 'visible', false)
      this.gridInstance.columnOption('new_last_24_hours_left', 'visible', false)
      this.gridInstance.columnOption('new_last_30_days_joined', 'visible', false)
      this.gridInstance.columnOption('new_last_30_days_left', 'visible', false)

      this.gridInstance.columnOption('image', 'visible', true)
      this.gridInstance.columnOption('new_last_24_hours', 'visible', true)
      this.gridInstance.columnOption('new_last_30_days', 'visible', true)
    },
    onExported(e) {
      this.disableExportColumns()
      e.component.endUpdate()
    },
    onExporting(e) {
      e.component.beginUpdate()
      this.enableExportColumns()

      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Sellers Activity')

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        const date = new Date()
        if (e.format === 'csv') {
          workbook.csv.writeBuffer().then(buffer => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Sellers Activity ${date.toLocaleDateString('en-US')}.csv`)
          })
        } else {
          workbook.xlsx.writeBuffer().then(buffer => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Sellers Activity ${date.toLocaleDateString('en-US')}.xlsx`)
          })
        }
      })
      e.cancel = true
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/common/grid.scss';
</style>
<style lang="scss" scoped>
#sellers-data-grid {
  min-height: calc(100dvh - 120px);
  max-height: calc(100dvh - 120px);
}
</style>
